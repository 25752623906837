<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-08-18 14:43:39
 * @LastEditors: ------
 * @LastEditTime: 2021-09-24 17:41:09
-->
<template>
  <div class="home_container">
    <!-- banner 内容开始 -->
    <div class="swipe">
      <swipe
        :images="imgs"
        :stop-propagation="false"
        @click="linkCom()"
        autoplay="3000"
        ref="child"
      ></swipe>
      <div class="swipeCon">
        <div class="items_i">
          <div class="swipeCon_item">
            <div class="swipeCon_itemTop"><span>5</span>年</div>
            <div>品牌历程</div>
          </div>
          <div class="swipeCon_item">
            <div class="swipeCon_itemTop">5城<span>16</span>店</div>
            <div>风靡全国</div>
          </div>
          <div class="swipeCon_item">
            <div class="swipeCon_itemTop"><span>156</span>万+</div>
            <div>已服务人次</div>
          </div>
          <div class="swipeCon_item">
            <div class="swipeCon_itemTop"><span>95</span>%+</div>
            <div>已好评度</div>
          </div>
        </div>
        <div class="lineBox">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <!-- banner 内容结束 -->

    <main>
      <!-- 服务项目-开始 -->
      <div class="serve_box">
        <div class="tip_title">
          <p class="h5">服务项目</p>
          <p class="h6">居家养老一站式服务</p>
        </div>
        <div class="serve_swipe">
          <div class="leftBtn" @click="next">
            <img src="@/assets/home/left.png" alt="" />
          </div>
          <swipe
            :images="serve_img"
            ref="swipertwo"
            @nextFont="nextFont"
            autoplay="none"
          ></swipe>
          <div class="rightBtn" @click="prev">
            <img src="@/assets/home/right.png" alt="" />
          </div>
        </div>

        <div v-show="fontShowNum == 0" class="swipe_font_con">
          <p>居家照顾</p>
          <p>
            被护理者大部分时间住在家里接受治疗和照护的方式
          </p>
        </div>
        <div v-show="fontShowNum == 1" class="swipe_font_con">
          <p>长护险服务</p>
          <p>
            在丧失日常生活能力,年老患病或身故时，侧重于提供护理障和经济补偿的保险产品
          </p>
        </div>
        <div v-show="fontShowNum == 2" class="swipe_font_con">
          <p>医院陪护</p>
          <p>
            有专业医疗技能的在医院看护病人的护工
          </p>
        </div>
        <div v-show="fontShowNum == 3" class="swipe_font_con">
          <p>老年餐定制</p>
          <p>
            为老年人定制适合的荤素搭配的营养餐
          </p>
        </div>
        <div v-show="fontShowNum == 4" class="swipe_font_con">
          <p>居家钟点</p>
          <p>
            非全日工作的家庭服务人员
          </p>
        </div>
        <div v-show="fontShowNum == 5" class="swipe_font_con">
          <p>适老化改造</p>
          <p>
            为适应中老年人而设计的适应老年人生理和心理需求的建筑以及室内空间环境
          </p>
        </div>
      </div>
      <div class="bili_link">
        <div class="bili_title">
          <div class="bili_aijiehu">爱介护</div>
          <div>
            居家养老服务平台
          </div>
          <img
            src="@/assets/home/bilibili_link.png"
            alt=""
            @click="window_open"
          />
        </div>
        <div class="bili_font">
          <p>
            爱介护成立于2015年，始终致力于为社区居家长者提供方便，快捷，高质量，人性化的健康介护服务，并逐步成为国内居家养老行业独具特色的品牌和服务连锁机构。
          </p>
          <p>
            主要服务项目有长护险服务，健康管家，生活照料，医院陪护，乐养服务，老年餐定制配送，钟点服务，乐养服务以及适老化改造等。
          </p>
        </div>
      </div>
      <!-- ----------我们的优势---------- -->
      <div class="our_advantage">
        <p class="our_title">我们的优势</p>
        <div class="our_con">
          <div>
            <img src="@/assets/home/our2.png" alt="" />
          </div>
          <div class="our_fonts our_fontsR">
            <p>完善的管理运营团队</p>
            <p>拥有护理人员的培训中心</p>
          </div>
        </div>
        <div class="our_con">
          <div class="our_fonts our_fontsL">
            <p>拥有医养一体化服务云平台灵活多样的服务模式及24小时保障服务</p>
          </div>
          <div>
            <img src="@/assets/home/our1.png" alt="" />
          </div>
        </div>
      </div>
      <!-- 我们的服务网点 -->
      <div class="website">
        <div class="website_title">我们的服务网点</div>
        <div class="website_address">
          <img src="@/assets/home/weSite.png" alt="" />
        </div>
        <div class="website_scroll serve_box">
          <div class="serve_swipe serve_swipe1">
            <div class="leftBtn" @click="next3">
              <img src="@/assets/home/left.png" alt="" />
            </div>
            <van-swipe
              autoplay="none"
              height="170"
              class="news_item"
              indicator-color="#ff9500"
              ref="swiperthree"
            >
              <van-swipe-item v-for="(item, index) in mySite" :key="index">
                <div class="site_font">
                  <p class="p1">{{ item.siteName }}</p>
                  <p class="p2">{{ item.siteAddress }}</p>
                  <p class="p3">{{ item.sitePhone }}</p>
                </div>
              </van-swipe-item>
            </van-swipe>
            <div class="rightBtn" @click="prev3">
              <img src="@/assets/home/right.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 新闻资讯 -->
      <div class="serve_box">
        <div class="tip_title">
          <p class="h5 h5_news">新闻资讯</p>
        </div>
        <div class="serve_swipe">
          <div class="leftBtn" @click="next2">
            <img src="@/assets/home/left.png" alt="" />
          </div>
          <van-swipe
            @change="onChange"
            autoplay="none"
            height="170"
            class="news_item"
            indicator-color="#ff9500"
            ref="swiper"
            :stop-propagation="false"
          >
            <van-swipe-item
              v-for="(image, index) in news_imgs"
              :key="index"
              @click="
                $router.push({
                  path: '/newsdetail',
                  query: {
                    id: news_id[index],
                  },
                })
              "
            >
              <img :src="image" />
            </van-swipe-item>
          </van-swipe>
          <div class="rightBtn" @click="prev2">
            <img src="@/assets/home/right.png" alt="" />
          </div>
        </div>
        <div class="news_box">
          <div class="news_title">
            {{ news_title[numIndex] }}
          </div>
          <div>
            {{ news_time[numIndex] }}
          </div>
          <p @click="$router.push('/newscenter')">
            更多 >>
          </p>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="partner">
        <div class="partner_title">合作伙伴</div>
        <div class="partner_icon">
          <img src="@/assets/home/partner1.png" alt="" />
          <img src="@/assets/home/partner2.png" alt="" />
          <img src="@/assets/home/partner3.png" alt="" />
          <img src="@/assets/home/partner4.png" alt="" />
          <img src="@/assets/home/partner5.png" alt="" />
          <img src="@/assets/home/partner6.png" alt="" />
          <img src="@/assets/home/partner7.png" alt="" />
          <img src="@/assets/home/partner8.png" alt="" />
        </div>
        <p @click="aboutUs()">
          更多 >>
        </p>
      </div>
      <!-- 服务项目-结束 -->
    </main>
  </div>
</template>

<script>
import { newsList } from "@/utils/request";
import Swipe from "./components/swipe";

export default {
  name: "Home",
  components: { Swipe },
  data() {
    return {
      imgs: [
        require("@/assets/home/banner1.png"),
        require("@/assets/home/banner2.png"),
      ],
      serve_img: [
        require("@/assets/home/zhuJiaZhaoHu.png"),
        require("@/assets/home/changHuXianFuWu.png"),
        require("@/assets/home/yiYuanPeiHu.png"),
        require("@/assets/home/laoNianCan.png"),
        require("@/assets/home/juJiaZhongDian.png"),
        require("@/assets/home/shiLaoHua.png"),
      ],
      mySite: [
        {
          siteName: "艾禹护护理站",
          siteAddress: "地址:上海市浦东新区张杨路2590号",
          sitePhone: "电话:5065-6698",
        },
        {
          siteName: "德心居护理站",
          siteAddress: "地址:上海市浦东新区东三里桥路237-2号",
          sitePhone: "电话:5858-1170",
        },
        {
          siteName: "艾悦护护理站",
          siteAddress: "地址:上海市浦东新区海阳路452号",
          sitePhone: "电话:5089-8978",
        },
        {
          siteName: "艾高护护理站",
          siteAddress: "地址:上海市浦东新区和龙路447-3号",
          sitePhone: "电话:5890-9055",
        },
        {
          siteName: "爱介护沪东为老服务中心",
          siteAddress: "地址:上海市浦东新区五莲路430号",
          sitePhone: "电话:5065-6698",
        },
        {
          siteName: "爱介护北蔡为老服务中心",
          siteAddress: "地址:上海市浦东新区锦尊路229号",
          sitePhone: "电话:5858-1170",
        },
        {
          siteName: "总部",
          siteAddress: "地址:上海市浦东新区崮山路968号",
          sitePhone: "电话:5888-6722",
        },
      ],
      fontShowNum: 0,
      numIndex: 0,

      newsList: [], //3条新闻列表
      news_imgs: [],
      news_title: [],
      news_time: [],
      news_id: [],
    };
  },
  created() {
    newsList({
      pageNo: 1,
      pageSize: 3,
    }).then((res) => {
      this.newsList = res.data.result.records;
      this.newsList.forEach((arr) => {
        // return arr.newsImg;

        this.news_id.push(arr.id);
        this.news_imgs.push(arr.newsImg);
        this.news_title.push(arr.newsTitle);
        this.news_time.push(arr.createTime);
      });
    });
  },
  methods: {
    linkCom() {
      this.$refs.child.swipeItem(); //调用子组件方法..第一个轮播
    },
    next() {
      this.$refs.swipertwo.nextTap(); //第二个轮播 .. 上一个
    },
    next2() {
      this.$refs.swiper.next();
    },
    next3() {
      this.$refs.swiperthree.next();
    },
    prev() {
      this.$refs.swipertwo.prevTap(); //第二个轮播 .. 下一个
    },
    prev2() {
      this.$refs.swiper.prev();
    },
    prev3() {
      this.$refs.swiperthree.prev();
    },
    nextFont(val) {
      this.fontShowNum = val; //子组件传来的轮播图对应索引
    },
    window_open() {
      window.open("https://www.bilibili.com/video/BV1NX4y137ub");
    },
    onChange(num) {
      this.numIndex = num;
    },
    aboutUs() {
      this.$router.push({
        path: "/aboutUs",
      });
      this.$store.commit("setjobNum", 3);
    },
  },
};
</script>

<style scoped lang="less">
.swipe {
  .swipeCon {
    position: relative;
    z-index: 11;
    top: -30px;
    .items_i {
      border-radius: 5px;
      box-shadow: 0px 4px 16px rgba(105, 115, 163, 0.11);
      background: #ffffff;
      width: 90%;
      margin: auto;
      text-align: center;
      display: flex;
      justify-content: space-around;
      div {
        height: 52px;
      }
      .swipeCon_item {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 10px;
        color: var(--color666);
        margin-top: 8px;
        .swipeCon_itemTop {
          color: var(--homeColor);
          span {
            font-size: 22px;
            font-weight: 600;
          }
        }
      }
    }
    .lineBox {
      position: absolute;
      top: 12px;
      left: 100px;
      .line {
        width: 85px;
        height: 30px;
        border-left: 1px solid #d1d1d1;
        display: inline-block;
      }
    }
  }
}
main {
  text-align: center;

  .serve_box {
    .tip_title {
      .h5 {
        color: var(--homeColor);
        font-size: 22px;
        font-weight: bold;
      }
      .h5_news {
        padding: 40px 0 20px 0;
      }
      .h6 {
        font-size: 16px;
        color: var(--color666);
        margin-top: 10px;
      }
    }
    .serve_swipe {
      padding: 16px 16px 0 16px;
      position: relative;
      .leftBtn {
        position: absolute;
        z-index: 1;
        top: calc(50% - 22px);
        img {
          width: 44px;
          height: 44px;
        }
      }
      .rightBtn {
        position: absolute;
        z-index: 1;
        right: 16px;
        top: calc(50% - 22px);
        img {
          width: 44px;
          height: 44px;
        }
      }
    }
    .serve_swipe1 {
      padding: 0;
      margin-top: -8px;
      padding-left: 16px;
      background-color: var(--siteColor);
      text-align: left;
      height: 190px;
      .site_font {
        color: var(--white);
        font-size: 20px;
        width: 73%;
        line-height: 1.4;
        margin: auto;
        margin-top: 30px;

        .p1 {
          font-size: 21px;
          font-weight: 600;
          padding-bottom: 8px;
        }
      }
    }
    .swipe_font_con {
      padding: 18px 16px 20px 16px;
      height: 90px;
      color: var(--color333);
      text-align: left;
      p:nth-child(1) {
        font-size: 18px;
        padding-bottom: 16px;
      }
      p:nth-child(2) {
        font-size: 16px;
        line-height: 1.5;
      }
    }
    .news_box {
      font-size: 18px;
      text-align: left;
      padding: 16px;
      line-height: 1.5;
      .news_title {
        padding-bottom: 10px;
      }
      p {
        text-align: center;
        color: var(--homeColor);
        font-size: 20px;
        font-weight: 500;
        margin-top: 20px;
      }
    }
    .news_item {
      height: 180px;

      img {
        width: 100%;
        height: 176px;
      }
    }
  }
  .bili_link {
    color: var(--white);
    background-color: var(--bilibiColor);
    padding: 22px 0 38px 0;
    font-size: 16px;
    line-height: 1.6;

    .bili_title {
      .bili_aijiehu {
        font-size: 22px;
        font-weight: bold;
      }
      img {
        width: 100%;
        margin-top: 20px;
      }
    }
    .bili_font {
      text-align: left;
      padding: 0 16px;
      p:nth-child(1) {
        padding-bottom: 10px;
      }
    }
  }
  .our_advantage {
    padding: 20px 0;
    font-weight: bold;
    .our_title {
      font-size: 22px;
      color: var(--homeColor);
      padding: 28px 0 40px 0;
    }
    .our_con {
      display: flex;
      font-size: 12px;
      color: #ff9500;
      font-weight: 600;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 18px;
      img {
        width: 218px;
        height: 125px;
      }
      .our_fonts {
        text-align: left;
        line-height: 1.5;
        margin: -6px 0 0 8px;
      }
      .our_fontsR {
        padding-right: 16px;
      }
      .our_fontsL {
        padding-left: 10px;
      }
    }
  }
  .website {
    // margin-bottom: 40px;
    .website_address img {
      height: 375px;
      width: 375px;
    }
    .website_title {
      font-size: 22px;
      color: var(--homeColor);
      font-weight: bold;
      padding: 30px 0;
    }
    .website_scroll {
      .scroll_item {
      }
    }
  }
  .partner {
    background-color: var(--bilibiColor);
    padding: 20px 8px 50px 8px;
    margin-top: 20px;
    color: var(--white);
    .partner_title {
      padding: 30px 0 20px 0;
      font-size: 22px;
      font-weight: bold;
    }
    .partner_icon {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      img {
        margin-top: 10px;
        width: 168px;
        height: 72px;
      }
    }
    p {
      font-size: 20px;
      font-weight: 500;
      margin-top: 40px;
    }
  }
}
</style>
