<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-08-24 13:23:50
 * @LastEditors: ------
 * @LastEditTime: 2021-08-26 16:08:33
-->
<template>
  <div class="swipe_com">
    <van-swipe
      :autoplay="autoplay"
      :height="height"
      @change="onChange"
      indicator-color="#ff9500"
      ref="swiper"
    >
      <van-swipe-item v-for="(image, index) in images" :key="index">
        <img :src="image" />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    autoplay: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      index: null,
    };
  },
  methods: {
    onChange(num) {
      // console.log(num);
      this.index = num;
      this.$emit("nextFont", this.index);
    },

    swipeItem() {
      //第一个轮播数据
      if (this.index == 1) {
        let routeUrl = this.$router.resolve({
          path: "./partner",
        });
        window.open(routeUrl.href, "_blank");
      }
    },

    nextTap() {
      this.$refs.swiper.next();
    },
    prevTap() {
      this.$refs.swiper.prev();
    },
  },
};
</script>

<style scoped>
img {
  width: 100%;
}
</style>
